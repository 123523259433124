<template>
  <div class="p-grid" v-if="canAccess('cobranzas_titulares')">
    <div class="p-col-12">
      <div class="card card-w-title">
        <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"/>
        <ProgressBar mode="indeterminate" v-if="processing"/>

        <div class="p-col-12" style="padding-top: 20px">

          <div class="p-text-right p-fluid  p-ml-2">
            <div class="p-grid p-fluid  p-ml-2">
              <div class="p-field   p-fluid   p-ml-2" v-if="isAdmin">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedCountry" :options="countries" optionLabel="country"
                                                 @change="selectedCity=null;getData()"/>
                                        <label for="country">País</label>
                                    </span>
              </div>
              <div class="p-field   p-fluid  p-ml-2">
                                    <span class="p-float-label df">
                                       <Dropdown v-model="selectedCity" :options="cities" optionLabel="name"
                                                 @change="getData()"/>
                                        <label for="months">Ciudad</label>
                                    </span>
              </div>
              <div class="p-field p-fluid  p-ml-2 p-mr-2">
                                  <span class="p-float-label">
                                        <Calendar id="start_date" :showIcon="true" v-model="from"
                                                  dateFormat="yy-mm-dd"
                                                  :yearNavigator="true" yearRange="2018:2050" class="p-inputtext-sm"
                                                  @date-select="getData()"/>
                                        <label for="start_date">Desde</label>
                                    </span>
              </div>
              <div class="p-field p-fluid  p-mr-2">
                                  <span class="p-float-label">
                                        <Calendar id="end_date" :showIcon="true" v-model="to"
                                                  dateFormat="yy-mm-dd"
                                                  :yearNavigator="true" yearRange="2018:2050" class="p-inputtext-sm"
                                                  @date-select="getData()"/>
                                        <label for="end_date">Hasta</label>
                                    </span>
              </div>
              <div class="p-field p-fluid  p-mr-2">
                   <span class="p-input-icon-left  p-mr-2">
                      <i class="pi pi-search"/>
                      <InputText v-model="query" placeholder="Buscar" @keypress.enter="getData"/>
                  </span>
              </div>

            </div>
          </div>

        </div>


        <DataTable :value="employeeList" :filters="filters"
                   class="p-datatable-responsive-demo p-datatable-sm p-datatable-striped"
                   v-model:selection="currentItem" selectionMode="single" dataKey="id" :paginator="true"
                   :scrollable="isScrollable" paginatorPosition="bottom"
                   :rows="page.per_page" :lazy="true" :totalRecords="page.total"
                   :loading="processing" @page="onPage($event)"
                   responsiveLayout="scroll"
                   @row-select="selectData($event)">
          <template #header>
            <div class="table-header">
              <h5 class="p-m-0">Lista de Titulares</h5>
            </div>
          </template>
          <Column field="id" header="#" headerStyle="width: 100px">
            <template #body="slotProps">
              <span class="p-column-title">#</span>
              {{ slotProps.data.id }}
            </template>
          </Column>
          <Column field="dni" header="Cedula" headerStyle="width: 150px">
            <template #body="slotProps">
              <span class="p-column-title">Cédula</span>
              {{ slotProps.data.dni }}
            </template>
          </Column>
          <Column field="name" header="Nombre" headerStyle="width: 150px">
            <template #body="slotProps">
              <span class="p-column-title">Nombre</span>
              {{ slotProps.data.name }}
            </template>
          </Column>
          <Column field="last_name" header="Apellido" headerStyle="width: 150px">
            <template #body="slotProps">
              <span class="p-column-title">Apellido</span>
              {{ slotProps.data.last_name }}
            </template>
          </Column>
          <Column field="email" header="mail" headerStyle="width: 250px">
            <template #body="slotProps">
              <span class="p-column-title">Mail</span>
              {{ slotProps.data.email }}
            </template>
          </Column>
          <Column field="phone" header="Teléfono" headerStyle="width: 100px">
            <template #body="slotProps">
              <span class="p-column-title">Teléfono</span>
              {{ slotProps.data.phone }}
            </template>
          </Column>
          <Column header="País" headerStyle="width: 100px">
            <template #body="slotProps">
              <span class="p-column-title">País</span>
              {{ slotProps.data?.country?.name }}
            </template>
          </Column>
          <Column header="Ciudad" headerStyle="width: 100px">
            <template #body="slotProps">
              <span class="p-column-title">Ciudad</span>
              {{ slotProps.data?.city?.name }}
            </template>
          </Column>
          <Column header="Fecha Creación" headerStyle="width: 100px">
            <template #body="slotProps">
              <span class="p-column-title">Fecha Creación</span>
              {{ slotProps.data?.created_at }}
            </template>
          </Column>
          <Column headerStyle="width: 150px">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success  p-mr-2" v-tooltip="'Titulares'"
                      @click="edit(slotProps.data)"/>
            </template>
          </Column>
          <template #expansion="slotProps">
            <ProgressBar mode="indeterminate" style="height: 2px" v-if="slotProps.data.loadingEnroll"/>
            <div class="orders-subtable" v-if="slotProps.data.enrollments">
              <h5>Matrículas de {{ `${slotProps.data.name}  ${slotProps.data.last_name}` }}</h5>
              <DataTable :value="slotProps.data.enrollments" responsiveLayout="scroll" row-hover="false">
                <Column field="sequential" header="N. matrícula" sortable></Column>
                <Column field="status" header="Estado" sortable></Column>
                <Column field="plan.name" header="Plan" sortable></Column>
              </DataTable>
            </div>
          </template>
        </DataTable>
        <Sidebar v-model:visible="editDialog" :baseZIndex="1000" position="right" :style="'width:70%'"
                 class="custombar">
          <h3>{{ currentItem ? 'Editar titular' : 'Nuevo titular' }}</h3>
          <holder ref="holderForm" :holder="currentItem" :edit="true" :typeEmployees=typeEmployees
                  :typeHousing=typeHousing @cancel="editDialog = false" @ok="getData"></holder>
          <div class="p-grid p-jc-end">
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="editDialog = false"/>
            <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveHolder"/>
          </div>
        </Sidebar>
        <Sidebar v-model:visible="viewStudents" :baseZIndex="1000" position="right" :style="'width:70%'"
                 class="custombar">
          <h3>Lista de Beneficiarios</h3>
          <div v-if="currentItem?.contracts">
            <div v-for="(item ,idx) in  currentItem?.contracts" :key="idx">
                <h2>Matrícula: <b style="color: #007be5">{{item.enrollment.sequential}}</b></h2>
                <div v-if="item.enrollment?.students.length>0">
                  <DataTable :value="item.enrollment?.students"  class="content">
                   <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field" :rowClass="col.class">
                   </Column>
                  </DataTable>
                </div>
                <div v-else>
                  <h4><b>No contiene beneficiarios</b></h4>
                </div>

            </div>
          </div>
          <div class="p-grid p-jc-end" style="margin-top: 16px">
            <br/>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="viewStudents = false"/>
          </div>
        </Sidebar>

      </div>
    </div>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>

<script>
import service from '../../../enrollment/service/holder.service'
import enrollService from '../../../enrollment/service/enrollment.service'
import catalogService from '../../../core/service/catalog.service'
import bouncer from "../../../../helpers/bouncer";
import {
  defineAsyncComponent
} from 'vue';

import {mapState, mapGetters} from "vuex";
import moment from "moment";

export default {
  mixins: [bouncer],
  refs: ['holderForm'],
  components: {
    holder: defineAsyncComponent(() => import('../../../enrollment/components/holders/Holder.vue'))
  },
  data() {
    return {
      columns: [
        {field: 'dni', header: 'DNI'},
        {field: 'name', header: 'Nombre'},
        {field: 'last_name', header: 'Apellido'},
        {field: 'email', header: 'Correo', class:'content'},
        {field: 'phone', header: 'Teléfono'},
      ],
      viewStudents:false,
      processing: false,
      editDialog: false,
      filters: {},
      typeEmployees: [],
      typeHousing: [],
      query: '',
      employeeList: null,
      currentItem: null,
      search: '',
      to:null,
      from:null,
      page: {
        page: 1,
        per_page: 10,
        total: 0,
      },
      window: {
        width: 0,
        height: 0
      },
      expandedRows: [],
      selectedCountry: null,
      selectedCity: null,
      countries: [],
    }
  },
  carService: null,
  eventService: null,
  nodeService: null,
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  mounted() {
    if (this.canAccess('cobranzas_titulares')) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Edición titulares', url: "javascript:void(0);"},
      ]);
      this.getData();
      this.getCountries();
      catalogService.get('holders/types/employee').then(x => {
        this.typeEmployees = [];
        Object.keys(x.data).forEach(k => {
          this.typeEmployees.push({value: k, label: x.data[k]});
        });
        this.typeEmployees = Object.values(this.typeEmployees)
      }).finally(()=>{
        this.processing=false
      });
      catalogService.get('holders/types/housing').then(x => {
        this.typeHousing = [];
        Object.keys(x.data).forEach(k => {
          this.typeHousing.push({value: k, label: x.data[k]});
        });
      });
    }
  },
  methods: {
    getCountries() {
      catalogService.get(`select/countries/cities`).then(response => {
        this.countries = response.data;
      });
    },
    sync() {
      this.processing = true;
      service.synchronize({country_id: this.selectedCountry?.id }).then(() => {
        this.getData();
      }).catch(err => {
        if (err.response.status === 404) {
          this.$toast.add({
            severity: 'info',
            summary: 'Sincronización',
            detail: 'No existen registros por sinconizar',
            life: this.$utils.toastLifeTime()
          });
        }else{
          const message = err.response.data;
          let error = this.$utils.formatError(message);
          this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
        }
      }).finally(() => this.processing = false)
    },
    onPage(event) {
      this.page.page = event.page + 1;
      this.getData()
    },
    getData() {
      this.editDialog = false;
      this.processing = true;
      this.$nextTick(() => {
        service.getAllRaise({
          ...this.page,
          country_id: this.isAdmin ? undefined : this.user?.employee?.country_id,
          q: this.query !== '' ? this.query : undefined,
          ...{'to': this.to ? moment(this.to).format("YYYY-MM-DD") : null},
          ...{'from': this.from ? moment(this.from).format("YYYY-MM-DD") : null},
          ...{'country_id': this.selectedCountry ? this.selectedCountry.id : null},
          ...{'city_id': this.selectedCity ? this.selectedCity.id : null}
        }).then(x => {
          this.employeeList = x.data.data;
          this.page.total = x.data.total;
          this.processing = false;

        }).finally(()=>{
          this.processing=false
        })
      });
    },

    filter() {
    },
    selectData() {
      this.viewStudents = true;
        this.$store.dispatch('enroll/setCountry', this.currentItem.country);
        location.href='#/raise-money/enrollments/' + this.currentItem.id;
       window.location.reload(true)
    },
    edit(data) {
      this.currentItem = data;
      this.editDialog = true;
    },
    students(data) {
      this.currentItem = data;
      this.viewStudents = true;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    saveHolder() {
      this.$refs.holderForm.$refs.holderForm.save().then(() => {
        this.getData()
      })
    },
    onRowExpand(e) {
      e.data.loadingEnroll = !e.data.enrollments;
      enrollService.getByHolder(e.data.id).then(x => {
        e.data.enrollments = x.data;
      }).finally(() => {
        e.data.loadingEnroll = false
      })
    }
  },
  computed: {
    ...mapState('auth', ['isAdmin']),
    ...mapState('auth', ['user']),
    ...mapGetters('bread', ['breadcrumb']),
    isScrollable() {
      return this.window.width > 800
    },
    cities() {
      return this.selectedCountry ? this.selectedCountry.cities : [];
    },
  }
}
</script>

<style lang="scss" scoped>
.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 50em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-responsive-demo {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: .4rem;
          min-width: 30%;
          display: inline-block;
          margin: -.4em 1em -.4em -.4rem;
          font-weight: bold;
        }

        &:last-child {
          border-bottom: 1px solid var(--surface-d);
        }
      }
    }
  }
}

> > td {
  word-wrap: break-word !important
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-progressbar {
  height: 1px;
}


@media screen and (min-width: 50em) {
  .filter {
    width: 360px !important;
  }
}

::v-deep .p-dialog-content {
  height: 100%;
  overflow: auto !important;

}

</style>


